import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { config } from 'data/config'
import PublicMobileModalWithAuth from 'components/PublicMobileModalWithAuth'
import Button from 'components/home/common/Button'
import Input from 'components/form/Home/Input'
import ControlledWishlistSelect from 'components/form/AsyncSelects/ControlledWishlistSelect'
import { useMutation } from 'urql'
import { ADD_PROPERTY_TO_WISHLIST_MUTATION, CREATE_WISHLIST_MUTATION } from 'data/graphql/queries/client/wishlist'
import { ModalProps } from 'data/types/modal'
import { useWishlistCount } from 'data/hooks/useWishlistCount'

interface WishlistModalProps extends ModalProps {
  setActive: (active: boolean) => void
  propertyId?: string
  placeId?: string
}

interface WishlistFormFields {
  wishlist: {
    id: string
    title: string
  }
}

const WishlistModal = ({
  isOpen,
  onClose,
  setActive,
  propertyId,
  placeId,
}: WishlistModalProps) => {
  const { handleSubmit, register, watch, reset, control, formState: { errors }, clearErrors } = useForm<WishlistFormFields>()

  const {
    wishlistCount,
    fetchingWishlistCount,
  } = useWishlistCount()

  const [{ fetching: isCreateWishlistFetching }, createWishlist] = useMutation(CREATE_WISHLIST_MUTATION)
  const [{ fetching: isAddPropertyToWishlistFetching }, addToWishlist] = useMutation(ADD_PROPERTY_TO_WISHLIST_MUTATION)

  useEffect(() => {
    clearErrors()
  }, [watch('wishlist.title')])

  const closeModal = (success: boolean) => {
    setActive(success)
    reset()
    onClose()
  }

  const onSubmit = (data: WishlistFormFields) => {
    setActive(true)
    if (data.wishlist.id === '-1') {
      createWishlist({
        input: {
          title: data.wishlist.title,
          propertyId: propertyId,
          placeId: placeId,
        },
      }).then(
        (response) => closeModal(!response.error),
      )
    } else {
      addToWishlist({
        input: {
          wishlistId: data.wishlist.id.toString(),
          propertyId: propertyId,
          placeId: placeId,
        },
      }).then(
        (response) => closeModal(!response.error),
      )
    }
  }

  return (
    <PublicMobileModalWithAuth
      title="Save to Wishlist"
      isOpen={isOpen}
      onClose={onClose}
      onOpen={reset}
      className={{
        panel: 'px-25 pb-safe-offset-20',
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-25"
      >
        <ControlledWishlistSelect
          control={control}
          name="wishlist"
          block
          variant="customer"
          required
        />

        {/* Create New */}
        {watch('wishlist')?.id === '-1' && (
          <>
            <Input
              variant="grey"
              className={{
                container: 'mt-20',
              }}
              {...register('wishlist.title')}
              error={errors?.wishlist?.title?.message}
              label="Name this Wishlist"
              required={watch('wishlist.id') === '-1'}
            />

            {!fetchingWishlistCount && wishlistCount
              ? (
                <p className="mt-[6px] text-right text-grey-800 leading-24 text-13">
                  {wishlistCount}/{config.wishlistCreationLimit} Wishlists
                </p>
              )
              : null
            }
          </>
        )}

        <Button
          block
          style="modal"
          type="submit"
          className={{
            button: 'mt-70',
          }}
          isLoading={isCreateWishlistFetching || isAddPropertyToWishlistFetching}
        >
          Save
        </Button>
      </form>
    </PublicMobileModalWithAuth>
  )
}

export default WishlistModal
