import { serialize } from 'object-to-formdata'

export default class PropertyApiClient {
  constructor (http) {
    this.http = http
  }

  /**
   * Returns a list of available property filters
   *
   * @returns {Object}
   */
  async getFilters () {
    const result = await this.http.get('/properties/list/filters')
    return result.data.data
  }

  /**
   * Get a list of properties from the API
   *
   * @param {Object} filters Filters object
   *
   * @param {Object}         options Options object
   * @param {number}         [options.page = 1] Pagination page
   * @param {number}         options.limit Pagination limit
   * @param {boolean}        options.paginated
   * @param {(string|array)} options.includes
   * @returns Promise<{{properties: RestProperty[], meta: Object}}>
   */
  async getPublishedProperties (filters = {}, options = {}) {
    const result = await this.http.get('/properties', {
      params: {
        ...filters,
        page: options.page ?? 1,
        per_page: options?.per_page,
        limit: options.limit,
        paginated: options.paginated,
        includes: options.includes,
        media: options.media ?? true,
        reduced_resource: options.reduced_resource ?? false,
      },
    })

    return result.data.meta
      ? {
        properties: result.data.data,
        meta: result.data.meta,
      }
      : result.data.data
  }

  async getProperties (filters = {}, options = {}) {
    const result = await this.http.get('/properties/list/all', {
      params: {
        ...filters,
        page: options.page ?? 1,
        per_page: options?.per_page,
        limit: options.limit,
        paginated: options.paginated,
        includes: options.includes,
        media: options.media ?? true,
        reduced_resource: options.reduced_resource ?? false,
      },
    })

    return result.data.meta
      ? {
        properties: result.data.data,
        meta: result.data.meta,
      }
      : result.data.data
  }

  /**
   * Get a list of properties for the specified region
   *
   * @param {string} regionSlug The region slug to fetch properties for
   *
   * @param {Object} filters Options to filter the properties
   * @param {string} filters.arrival The arrival date to search for (YYYY-MM-DD)
   * @param {string} filters.departure The departure date to search for (YYYY-MM-DD)
   * @param {number} filter.bedroom The number of bedrooms to search for
   * @param {number} filter.adults The number of adults to search for
   * @param {number} filter.children The number of children to search for
   * @param {string} filter.property_tags_styles
   * @param {string} filter.property_tags_types
   * @param {string} filter.property_tags_highlights
   * @param {string} filter.property_tags_holiday_types
   * @param {string} filter.property_amenities_general
   * @param {string} filter.property_amenities_entertainment
   * @param {string} filter.property_amenities_outdoor
   * @param {string} filter.services_housekeeping
   * @param {string} filter.services_linen_towels
   * @param {string} filter.services_staff_services
   * @param {string} filter.services_other
   * @returns {Array}
   */
  async getPropertiesForRegion (regionSlug, subregionSlug, filters = {}) {
    const result = await this.http.get('/properties', {
      params: {
        region_slug: regionSlug,
        subregion_slug: subregionSlug,
        paginated: false,
        ...filters,
      },
    })
    return result.data.data
  }

  /**
   * Get a single property from the API
   *
   * @param {(string|number)} idOrSlug
   * @returns {RestProperty}
   */
  async getProperty (idOrSlug, filters = {}) {
    const result = await this.http.get(`/properties/${idOrSlug}`, {
      params: {
        idOrSlug: idOrSlug,
        ...filters,
      },
    })
    return result.data.data
  }

  /**
   * Get similar properties from the API
   *
   * @param {(string|number)} idOrSlug
   * @returns {Object}
   */
  async getSimilarProperties (idOrSlug, filters = {}) {
    const result = await this.http.get(`/properties/${idOrSlug}/similar`, {
      params: {
        ...filters,
      },
    })

    return result.data.data
  }

  /**
   * Get a list of featured properties
   *
   * @param {(string|array)} params.includes
   * @returns {Array}
   */
  async getFeaturedProperties (params) {
    const result = await this.http.get('/properties/featured', {
      params: {
        includes: params.includes,
      },
    })
    return result.data.data
  }

  /**
   * Update a property with new details
   *
   * @param {number} propertyId
   * @param {object} data
   * @returns {RestProperty}
   */
  async updateProperty (propertyId, data) {
    const result = await this.http.patch(`/properties/${propertyId}`, data)
    return result.data.data
  }

  /**
   * Create a new property
   *
   * @param {object} data
   * @returns {Object}
   */
  async createProperty (data) {
    if (data.main_image) {
      data = serialize({ ...data }, {
        indices: true,
      })
    }
    const result = await this.http.post('/properties', data)
    return result.data.data
  }

  /**
   *
   * @param {(string|number)} idOrSlug
   * @param {Object} params Search parameters
   * @param {string} params.arrival Arrival date
   * @param {string} params.departure Departure date
   * @param {number} params.adults No. of adults
   * @param {number} params.children No. of children
   * @returns
   */
  async checkAvailability (idOrSlug, params) {
    const result = await this.http.get(`/properties/${idOrSlug}/availability`, {
      params,
    })
    return result.data.data
  }

  /**
   * Delete a property
   *
   * @param {string | number} id Property ID
   */
  async deleteProperty (id) {
    await this.http.delete(`/properties/${id}`)
  }

  /**
   *
   * Update a property's notes
   * @param {number} propertyId
   * @param {object} data
   * @returns {Object}
   */
  async updatePropertyNotes (propertyId, data) {
    const result = await this.http.patch(`/properties/${propertyId}/internal_notes`, data)
    return result.data.data
  }
}
