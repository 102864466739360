import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'
import { CrossIcon, LoadingIcon, SearchAltIcon } from 'components/icons'
import { config } from 'data/config'
import { TripScheduleServiceSearch } from 'data/enums/tripe-schedule-service-filter'
import { graphql, useFragment } from 'gql'
import { GeneralServiceOrderByColumn, GeneralServicesFilterInput, MiniServicesSearchQuery, SortOrder } from 'gql/graphql'
import { useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { useDebounce } from 'use-debounce'
import ServiceSlide, { ServiceSlideItemProps } from 'components/sections/ServiceSlide'
import ServiceRequestModalWithAuth from 'components/modals/ServiceRequestModalWithAuth'
import { useQueryClient } from 'react-query'
import Input from 'components/form/Home/Input'
import { Container } from 'data/types/classnames'
import ClickableServiceSlide, { ClickableServiceSlide_ServiceFragment } from 'components/home/general-services/ClickableServiceSlide'
import { RestBooking } from 'data/types/booking'
import RadioGroupItem from 'components/modals/home/search/RadioGroupItem'

const MINI_SERVICE_SEARCH_QUERY = graphql(`
  query MiniServicesSearch(
    $page: Int!,
    $perPage: Int!,
    $filters: GeneralServicesFilterInput
    $orderBy: [GeneralServiceOrderByClause!],
  ) {
    services(
      page: $page,
      first: $perPage,
      filters: $filters,
      orderBy: $orderBy
    ) {
      data {
        id
        slug
        form_link
        survey_slug
        ...ClickableServiceSlide_ServiceFragment
        category {
          title
        }
        sub_category {
          title
        }
        title
        service_image {
          url
          file_name
        }
        images {
          url
          file_name
        }
        image_alt_text
        description
      }
    }
  }
`)

export interface MiniServiceSearchClassNames {
  container?: string,
  searchbar?: string,
  disclaimer?: ServiceDisclaimerClassNames,
  serviceCard?: ServiceSlideItemProps['className'],
  grid?: Container,
}

interface MiniServiceSearchProps {
  booking?: RestBooking
  selectedRegion?: string
  className?: MiniServiceSearchClassNames
  onSearchChange?: (term: string) => void
  showDisclaimer?: boolean
  initialSearchTerm?: string
  hrefOnly?: boolean,
}

type MiniSearchService = MiniServicesSearchQuery['services']['data'][number]

const MiniServiceSearch = ({
  booking,
  selectedRegion,
  className,
  onSearchChange,
  showDisclaimer = true,
  initialSearchTerm,
}: MiniServiceSearchProps) => {
  const [filter, setFilter] = useState<TripScheduleServiceSearch>(TripScheduleServiceSearch.POPULAR)
  const [searchTerm, setSearchTerm] = useState<string | undefined>(initialSearchTerm)

  const [showServiceModal, setShowServiceModal] = useState<{
    open: boolean
    service?: MiniSearchService
  }>({ open: false })

  const [searchDebounced] = useDebounce(searchTerm, 300)
  const queryClient = useQueryClient()

  const queryFilters: GeneralServicesFilterInput = useMemo(() => {
    const filters: GeneralServicesFilterInput = {
      search: searchDebounced,
    }

    if (selectedRegion) {
      filters.region = selectedRegion
    } else if (booking?.property?.region) {
      filters.region = booking.property.region.id.toString()
    }

    if (filter) {
      switch (filter) {
        case TripScheduleServiceSearch.EXPERIENCE:
          filters.categories = [config.services.experience.id]
          break
        case TripScheduleServiceSearch.SERVICE:
          filters.categories = [config.services.service.id]
          break
      }
    }

    return filters
  }, [filter, selectedRegion, searchDebounced, booking])

  const [{ data, fetching }] = useQuery({
    query: MINI_SERVICE_SEARCH_QUERY,
    variables: {
      page: 1,
      perPage: 100,
      filters: queryFilters,
      orderBy: [{
        column: GeneralServiceOrderByColumn.Popularity,
        order: SortOrder.Desc,
      }],
    },
  })

  const serviceClick = (service: MiniSearchService) => {
    if (service.form_link) {
      queryClient.refetchQueries(['bookings'])
      setShowServiceModal({
        open: true,
        service: service,
      })
    }
  }

  return (
    <>
      <div className={classNames(
        className?.container,
      )}>
        <div className={classNames(
          'sticky top-0 z-10 pt-10 bg-white',
          className?.searchbar,
        )}>
          <Input
            variant="grey"
            name="service"
            withRing={false}
            placeholder="What service are you looking for?"
            suffix={<SearchAltIcon className="w-16 h-16 text-grey-700" />}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value)
              onSearchChange?.(e.target.value)
            }}
            autoFocus={false}
          />

          <RadioGroup value={filter} onChange={setFilter} aria-label="Selected Filter" className="flex justify-evenly">
            <RadioGroupItem value={TripScheduleServiceSearch.POPULAR} label="Most Popular" />
            <RadioGroupItem value={TripScheduleServiceSearch.SERVICE} label="Services" />
            <RadioGroupItem value={TripScheduleServiceSearch.EXPERIENCE} label="Experiences" />
          </RadioGroup>

          {showDisclaimer && <ServiceDisclaimer className={className?.disclaimer} />}
        </div>

        <div className={classNames(
          'grid grid-cols-2 md:grid-cols-3 overflow-clip gap-x-16 gap-y-30 items-start',
          className?.grid?.container,
        )}>
          {fetching
            ? <LoadingIcon />
            : <>
              {data?.services.data.map((service) => {
                const [subheading, pill] = service.description?.split('\n') ?? [undefined, undefined]
                const serviceData = {
                  heading: service.title,
                  subheading: subheading,
                  pillText: pill ? [pill] : undefined,
                  image: {
                    media: service.service_image?.url,
                    alt_text: service.image_alt_text ?? service.title,
                    height: 210,
                    layout: 'fill' as const,
                  },
                }

                const clickableService = useFragment(ClickableServiceSlide_ServiceFragment, service)

                return (
                  <ClickableServiceSlide
                    key={service.id}
                    service={clickableService}
                    bookingId={booking?.id}
                    onClick={() => serviceClick(service)}
                  >
                    <ServiceSlide
                      className={{
                        serviceCard: {
                          ...className?.serviceCard,
                          heading: classNames('lg:!text-18', className?.serviceCard?.heading),
                          subheading: classNames('lg:!text-14', className?.serviceCard?.subheading),
                        },
                        image: { container: '!h-[190px]' },
                      }}
                      key={service.slug}
                      isImageLoaded={!fetching}
                      {...serviceData}
                    />
                  </ClickableServiceSlide>
                )
              })}
            </>
          }
        </div>
      </div>

      <ServiceRequestModalWithAuth
        isOpen={showServiceModal.open}
        onClose={() => setShowServiceModal({ open: false })}
        service={showServiceModal?.service}
        booking={booking}
      />
    </>
  )
}

interface ServiceDisclaimerClassNames {
  container?: string
  text?: string
}

const ServiceDisclaimer = ({ className }: { className?: ServiceDisclaimerClassNames }) => {
  const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(true)

  if (!isDisclaimerVisible) { return null }

  return (
    <div className={classNames(
      'relative py-12 mt-10 bg-blue-30 rounded-10 px-26',
      className?.container,
    )}>
      <button
        className="absolute top-8 right-8 rounded-4 hover:bg-grey-200 text-blue-1000"
        type="button"
        onClick={() => setIsDisclaimerVisible(false)}
      >
        <CrossIcon />
      </button>
      <p className={classNames(
        'text-center leading-[22px] text-12 tracking-1/4 text-grey-900 max-w-[382px] mx-auto',
        className?.text,
      )}>
        Select your preferred service and send a request. It will be sent directly to your dedicated concierge, who will respond with availability and a quote.
      </p>
    </div>
  )
}

export default MiniServiceSearch
