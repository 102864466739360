import React from 'react'
import { Share } from 'data/enums/share-target'
import classNames from 'classnames'

type ShareButtonProps = {
  shareTarget?: Share | Omit<Share, Share.CLIPBOARD>
  icon: JSX.Element
  href?: string
  onClick?: () => void
  variant?: 'round' | 'button'
}

const ShareButton = ({
  shareTarget,
  href,
  icon,
  onClick,
  variant = 'button',
}: ShareButtonProps) => {
  const buttonClassnames = classNames('flex items-center bg-grey-100 hover:bg-grey-250 text-blue-1000', {
    'px-40 py-16 w-full h-60 rounded-16 justify-between border border-grey-250': variant === 'button',
    'w-40 h-40 rounded-full justify-center': variant === 'round',
  })

  const buttonContent = (
    <>
      <p className={classNames('font-bold text-15 leading-20', { 'hidden': variant === 'round' })}>{shareTarget}</p>
      <div className="text-blue-1000">{icon}</div>
    </>
  )

  return (
    href
      ? (
        <a className={classNames({ 'w-full': variant === 'button' })} href={href} target="_blank" rel="noreferrer">
          <button type="button" className={buttonClassnames}>
            {buttonContent}
          </button>
        </a>
      )
      : (
        <button type="button" onClick={onClick} className={buttonClassnames}>
          {buttonContent}
        </button>
      )
  )
}

export default ShareButton
