import { useEffect, useState } from 'react'
import { Tab } from '@headlessui/react'
import { useRouterWithQuery } from 'data/hooks/useRouterWithQuery'
import { ReactChild } from 'data/types/types'
import classNames from 'classnames'

export const SEARCH_TAB_QUERY_PARAM = 'tab'

export enum SearchType {
  PROPERTIES = 'Properties',
  SERVICES = 'Services',
  PLACES = 'Places',
}

type SearchTabsProps = {
  headerChildren: ReactChild
  children: ReactChild
  isModal?: boolean
}

const SearchTabs = ({
  headerChildren,
  children,
  isModal,
}: SearchTabsProps) => {
  const router = useRouterWithQuery()
  const [searchType, setSearchType] = useState<number>(Object.values(SearchType).findIndex((type) => type === router.router.query.tab) || 0)

  const handleSearchTypeChange = (value: number) => {
    setSearchType(value)

    router.updateQueryParam(SEARCH_TAB_QUERY_PARAM, Object.values(SearchType)[value])
  }

  useEffect(() => {
    setSearchType(Object.values(SearchType).findIndex((type) => type === router.router.query.tab) || 0)
  }, [router.router.query.tab])

  return (
    <Tab.Group
      as="div"
      className="flex flex-col flex-grow"
      onChange={handleSearchTypeChange}
      selectedIndex={searchType}
    >
      <div className={classNames(
        'flex flex-col pb-5 mb-[7px]',
        {
          'bg-white pt-safe-offset-25 sticky top-0 z-10': !isModal,
        },
      )}>
        {headerChildren}

        <hr className={classNames(
          'border border-grey-100',
          {
            'mx-24': !isModal,
            '-mx-24': isModal,
          },
        )} />

        <Tab.List className={classNames(
          'flex justify-evenly bg-grey-150 rounded-10 py-[6px] px-14 h-40 mt-10',
          {
            'mx-24': !isModal,
          },
        )}>
          {Object.values(SearchType).map((option) => (
            <Tab
              key={option}
              value={option}
              className="rounded-full outline-none basis-2/5 lg:focus:ring-2 lg:focus:ring-primary lg:focus:ring-opacity-30"
            >
              {({ selected }) => (
                <div className={classNames(
                  'flex justify-center items-center rounded-25 px-20 h-full font-poppins text-grey-800 text-12 w-full cursor-pointer tracking-1/4 font-medium',
                  {
                    'bg-white': selected,
                  },
                )}>
                  {option}
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>
      </div>

      {/* Tab Content */}
      <Tab.Panels as="div" className="flex-1 flex-grow pb-30 overflow-y-auto">
        {children}
      </Tab.Panels>
    </Tab.Group>
  )
}

export default SearchTabs
