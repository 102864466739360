import { DestinationCountryAccordionDataQuery, DestinationSelectionCountriesDataQuery } from 'gql/graphql'
import React from 'react'
import { joinBy } from '@ama-selections/ui'
import Link from 'components/Link'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import { SelectedDestinationData } from 'components/popovers/DestinationSelectionPopover/_types'

interface DestinationRegionItemProps {
  country: DestinationSelectionCountriesDataQuery['countries']['data'][number]
  region: DestinationCountryAccordionDataQuery['regions']['data'][number]
  subRegion?: DestinationCountryAccordionDataQuery['regions']['data'][number]['subRegions'][number]
  className: string
  redirect?: boolean
  onSelect?: (destination: SelectedDestinationData) => void
}

const DestinationRegionItem = ({
  region,
  subRegion,
  country,
  onSelect,
  redirect,
  className,
}: DestinationRegionItemProps) => {
  const type = subRegion ? 'subRegion' : 'region'

  const pathName = '/' + joinBy([
    country.slug && '[country]',
    region.slug && '[region]',
    subRegion?.slug && '[subregion]',
  ], '/')

  return (redirect
    ? <Link
      key={`${type}-${subRegion?.id ?? region.id}`}
      href={{
        pathname: pathName,
        query: {
          country: country.slug,
          region: region.slug,
          ...(subRegion && { subregion: subRegion.slug }),
        },
      }}
    >
      <Paragraphs.LG className={className}>
        {subRegion?.value ?? region.value}
      </Paragraphs.LG>
    </Link>
    : <button
      type="button"
      className="text-left"
      onClick={() => onSelect?.({
        country: {
          id: country.id,
          value: country.value,
          slug: country.slug,
        },
        region: {
          id: region.id,
          value: region.value,
          slug: region.slug,
        },
        ...(subRegion && {
          subregion: {
            id: subRegion.id,
            value: subRegion.value,
            slug: subRegion.slug,
          },
        }),
        value: joinBy([
          country.value,
          region.value,
          subRegion?.value,
        ]),
      })}
    >
      <Paragraphs.LG className={className}>
        {subRegion?.value ?? region.value}
      </Paragraphs.LG>
    </button>
  )
}

export default DestinationRegionItem
