import { useQuery } from 'urql'
import { isServer } from 'data/helpers/ssr'
import { graphql } from 'gql'
import { DestinationSelectionCountriesDataQuery } from 'gql/graphql'
import Accordion from 'components/home/accordion'
import { useState } from 'react'
import Headers from 'components/home/common/typography/Headers'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import { SelectedDestinationData } from 'components/popovers/DestinationSelectionPopover/_types'
import DestinationRegionItem from './DestinationRegionItem'

const COUNTRY_QUERY = graphql(`
  query DestinationCountryAccordionData($filters: RegionsFilterInput) {
    regions (first: 100, filters: $filters) {
      data {
        id
        value
        slug
        subRegions {
          id
          value
          slug
        }
      }
    }
  }
`)

interface DestinationCountryAccordionProps {
  pause: boolean
  country: DestinationSelectionCountriesDataQuery['countries']['data'][number]
  className?: {
    container?: string
  }
  redirect?: boolean
  onSelect?: (destination: SelectedDestinationData) => void
}

const DestinationCountryAccordion = ({
  pause,
  country,
  className,
  onSelect,
  redirect = true,
}: DestinationCountryAccordionProps) => {
  const [viewAll, setViewAll] = useState<boolean>(false)

  const [{ data }] = useQuery({
    query: COUNTRY_QUERY,
    variables: {
      filters: {
        country_id: country.id,
      },
    },
    pause: pause && isServer,
  })

  return (
    <Accordion className={className?.container}>
      {({ open }) => (
        <>
          <Accordion.Header
            open={open}
            disabled={data?.regions.data.length === 0}
          >
            <Headers.H1 Tag="h4" className="inline-flex justify-between w-full text-grey-900">
              {
                open
                  ? (
                    <button
                      className="underline decoration-1 underline-offset-[3px] tracking-title leading-title"
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation()
                        onSelect?.({
                          country: {
                            id: country.id,
                            value: country.value,
                            slug: country.slug,
                          },
                          value: country.value,
                        })
                      }}
                    >
                      {country.value}
                    </button>
                  )
                  : country.value
              }

              {
                (open && (data?.regions.data ?? []).length > 0) && (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation()
                      setViewAll(!viewAll)
                    }}
                    className="font-normal underline text-primary-fresh focus:text-primary-active focus:outline-none hover:text-primary-active decoration-1 underline-offset-2 focus:underline-offset-3"
                  >
                    <Paragraphs.LG Tag="span" className="font-sans">
                      View {viewAll ? 'Less' : 'All'}
                    </Paragraphs.LG>
                  </button>
                )
              }
            </Headers.H1>
          </Accordion.Header>

          <Accordion.Content>
            <div className="flex flex-col">
              {
                data && data.regions.data.map((region) => (
                  <>
                    <DestinationRegionItem
                      country={country}
                      region={region}
                      onSelect={onSelect}
                      redirect={redirect}
                      className="text-grey-800 group-hover:text-primary-fresh group-focus:text-primary-fresh !leading-28"
                    />

                    {
                      viewAll && region.subRegions.map((subRegion) => (
                        <DestinationRegionItem
                          country={country}
                          region={region}
                          subRegion={subRegion}
                          onSelect={onSelect}
                          redirect={redirect}
                          className="ml-12 focus:outline-none group text-grey-650 group-hover:text-primary-fresh group-focus:text-primary-fresh !leading-28"
                        />
                      ))
                    }
                  </>
                ))
              }
            </div>
          </Accordion.Content>
        </>
      )}
    </Accordion>
  )
}

export default DestinationCountryAccordion
