import { Fragment } from 'react'
import classNames from 'classnames'
import { RadioGroup } from '@headlessui/react'
import { ReactTag } from '@headlessui/react/dist/types'

const RadioGroupItem = ({ value, label }: { value: string | null, label: string }) => {
  return (
    <RadioGroup.Option
      as={Fragment as ReactTag}
      value={value}
    >
      {({ checked }) => (
        <div className={classNames(
          'tracking-1/4 text-13 leading-22 mx-12 pt-15 capitalize font-bold cursor-pointer',
          {
            'text-grey-800 border-b-2 pb-8 border-primary-fresh': checked,
            'text-grey-500': !checked,
          },
        )}>
          {label}
        </div>
      )}
    </RadioGroup.Option>
  )
}

export default RadioGroupItem
