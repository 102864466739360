import { useState } from 'react'
import WishlistButton, { WishlistButtonProps } from 'components/WishlistButton'
import WishlistModal from 'components/modals/WishlistModal'
import useAuth from 'data/hooks/User/useAuth'
import { graphql } from 'gql'
import { useMutation } from 'urql'
import { useRouter } from 'next/router'

interface WishlistModalTriggerProps {
  buttonVariant: WishlistButtonProps['variant']
  className?: string
  initialActive?: boolean
  propertyId?: string
  placeId?: string
}

const WishlistModalTrigger = ({
  buttonVariant,
  className,
  initialActive,
  propertyId,
  placeId,
}: WishlistModalTriggerProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const router = useRouter()
  const [active, setActive] = useState(initialActive ?? false)
  const { user, checked } = useAuth()

  const [_, removeFromWishlist] = useMutation(graphql(`
    mutation removeFromWishlist($input: RemoveFromWishlistInput!) {
      removeFromWishlist(input: $input) {
        id
      }
    }
  `))

  const onWishlistButtonClicked = () => {
    if (user && checked && active) {
      setActive(false)
      removeFromWishlist({
        input: {
          propertyId: propertyId,
          placeId: placeId,
          token: router?.query?.token as string ?? undefined,
        },
      }).then((response) => {
        if (response.error) {
          setActive(true)
        }
      })
    } else {
      setIsModalOpen(true)
    }
  }

  return (
    <>
      <WishlistButton
        visible={true}
        onClick={(e) => {
          e.preventDefault()
          onWishlistButtonClicked()
        }}
        active={active}
        variant={buttonVariant}
        className={className}
      />

      <WishlistModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        setActive={setActive}
        propertyId={propertyId}
        placeId={placeId}
      />
    </>
  )
}

export default WishlistModalTrigger
